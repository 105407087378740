<template>
  <div class="detail">
<!--  导航  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="fromName.name!=null" :to="{path:this.fromPath}">{{ fromName.name }}</el-breadcrumb-item>
      <el-breadcrumb-item>软件详情</el-breadcrumb-item>
    </el-breadcrumb>
    <button class="el-breadcrumb-button" @click="toLastPage">
      <img src="../../../src/assets/images/returnPage.png" alt="返回">返回</button>
    <div class="top">
      <div class="left">
        <img :src="dataObj.logoUrl" alt="暂无图标">
      </div>
      <div class="center">
        <!-- 放大图片 -->
        <div class="viewer-dialog-box">
          <div class="img-box">
            <div
                class="img-wrap"
                v-viewer
                ref="viewer"
                v-show="bigImgView">
              <img
                  :src="bigImgSrc"
                  style="width:100%;"
              >
            </div>
          </div>
        </div>
        <div class="one">
          <div class="one_name">
            <span>{{dataObj.name}}</span>
            <span>{{dataObj.version}}</span>
          </div>
          <div class="one_ping">
            <el-rate
                    v-model="dataObj.score || 0"
                    disabled
                    show-score
                    :colors="['#f0141a', '#f0141a', '#f0141a']"
                    text-color="#f0141a"
                    score-template="{value}">
            </el-rate>
          </div>
        </div>
        <div class="two">
          <div class="item" style="min-width: 17%;">大小：{{dataObj.appSize}}</div>
          <div class="item" style="min-width: 22%">更新时间：{{dataObj.createTime | dataFormat}}</div>
          <div class="item" style="min-width: 23%">类型：{{dataObj.categoryName}}</div>
          <div class="item" style="min-width: 45%;">开发者：{{dataObj.developerName}}</div>
        </div>
        <div class="three">
          {{dataObj.versionDesc}}
        </div>

      </div>
      <div class="right">
        <a :href="dataObj.downloadUrl">
          <el-button @click="download" class="download" type="primary">立即下载</el-button>
        </a>
      </div>
    </div>
    <div class="banner" v-if=" dataObj.pictures && dataObj.pictures.length">
      <el-carousel trigger="click" :interval="5000" type="card" height="330px">
        <el-carousel-item v-for="item in dataObj.pictures" :key="item">
          <img :src="item" @click="openImageProxy($event)" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="pro">
      <span class="tit">软件简介</span>
      <div class="cont" v-html="dataObj.appDesc"></div>
    </div>
    <div class="pro">
      <span class="tit">安装步骤</span>
      <div class="cont" v-html="dataObj.step"></div>
      <el-row>
        <el-button type="primary" @click="$router.push('/detailPost/103')">安装步骤</el-button>
      </el-row>
    </div>
    <div class="rate">
      <div class="rate_tit">
        软件评分
      </div>
      <div class="rate_content">
        <div class="rate_content_one">
          <div class="rate_left">
            <div><span class="big">{{dataObj.score ? dataObj.score :0}}</span> <span class="small">分</span></div>
            <div class="tu">
              <el-rate
                      v-model="dataObj.score || 0"
                      disabled
                      :colors="['#f0141a', '#f0141a', '#f0141a']"
                      text-color="#f0141a">
              </el-rate>
            </div>
            <div style="font-size: 14px;color: #797979; text-align: center;">{{dataObj.scorenum ? dataObj.scorenum :
              '0'}}人评分
            </div>
          </div>
          <div class="rate_right">
            <div class="rate_right_item" v-for="item in rateProportionList">
              <el-rate
                      :value="item.sumType"
                      disabled
                      :colors="['#797979','#797979','#797979']"
                      text-color="#ff9900"
              >
              </el-rate>
              <div class="rate_right_item_line"></div>
              <div class="text">{{(item.score ? item.score :'0') + '%'}}</div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="rate_content_two">
          <div class="top_">
            <span>我的评分：</span>
            <el-rate
                    v-model="rateValue"
                    v-if="!finishRateValue"
                    :colors="['#ed1b24','#ed1b24','#ed1b24']">
            </el-rate>
            <el-rate
                    :value="finishRateValue"
                    disabled
                    v-if="finishRateValue"
                    :allow-half="true"
                    :colors="['#ed1b24','#ed1b24','#ed1b24']">
            </el-rate>
          </div>
          <div class="btn">
            <el-button :disabled="!!finishRateValue" type="primary" @click="rateChange">打分</el-button>
          </div>
        </div>
      </div>

    </div>
    <div class="comment">
      <div class="coo_top">
        <el-input
                type="textarea"
                class="comment_input"
                placeholder="请输入评论内容"
                maxlength="200"
                clearable
                :minlength="200"
                show-word-limit
                v-model="comment">
        </el-input>
        <el-button type="primary" @click="setComments">评论</el-button>
      </div>
      <div class="comm_content">
        <div class="item" v-for="item in commentList" :key="item.id">
          <div class="left">
            <div class="one">
              <img :src="item.avatarPath" alt="">

            </div>
            <div class="two">
              <div style="font-size: 16px; font-weight: 700;">{{item.username|commentFormatName}} <span
                      style="font-size: 12px; color: #797979;margin-left: 26px; font-weight: 500;">{{item.createTime | dataFormatDetail}}</span>
              </div>
              <div style="font-size: 12px; color: #797979;margin-top: 12px;">{{item.content}}</div>
            </div>
          </div>
          <div class="right">
            <i :class="[item.likestatus ? 'icon-dianzan': 'icon-dianzan1']" class=" comm iconfont "
               @click="changeRate(item.id)"></i>
            <span style="font-size: 12px; color: #ed1b24;margin-left: 8px;">{{item.likeCount ? item.likeCount : "0" }}</span>

          </div>
        </div>
        <div class="page_" v-if="total">
          <el-pagination
                  @current-change="currentChange"
                  layout="prev, pager, next"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getSoftDetail,
    getComments,
    setComments,
    DetailRateZan,
    softRate,
    getRateToken,
    getRateProportion,
    download
  } from '@/utils/network'

  export default {
    data() {
      return {
        bigImgView: false,
        bigImgSrc: "",
        fromPath:"",
        fromName: {},
        app_id: null,
        isShow:true,
        dataObj: {},  //详情数据
        comment: '',   //评论内容
        commentList: [],  //已评论列表
        rateValue: null,  //评分
        // 分页
        pageSize: 4,  //评论展示每页条数
        currentPage: 1,  //当前页码
        total: null,  //评论总数
        isLikeComment: false, //是否点赞评论
        isLogin: false,
        finishRateValue: '', //已经评分
        rateProportionList: [], //评分比例
        //导航数组
        navs: [
          {
            path: '/home',
            name: '首页'
          }, {
            path: '/hot',
            name: '热门软件推荐'
          },{
            path: '/cate',
            name: '软件分类'
          }, {
            path: '/install',
            name: '装机必备'
          },{
            path: '/search',
            name: '搜索'
          },{
            path: '/new',
            name: '最新上架'
          }
        ]
      }
    },
    beforeRouteEnter(to,from,next) {
      next(vm => {vm.fromPath = from.path
        for(let member of vm.navs) {
          if (vm.fromPath === member.path&&vm.fromPath!='/home') {
            vm.fromName = member
          }
        }})
    },
    created() {
      this.app_id = this.$route.params.id
      this.getData()
      this.getRateProportion()
      this.getCommentData()
      this.getRateToken()
      this.getRateProportion()
      this.$bus.$on('loginDialogClose', () => {
        if (!this.isLogin) {
          this.rateValue = null
        }
      })
    },
    watch: {
      $route(val) {
        this.app_id = this.$route.params.id
        this.getData()
        this.getRateProportion()
        this.getCommentData()
        this.getRateToken()
        this.getRateProportion()
      },
      token(curVal,oldVal){
        this.getData()
        this.getRateProportion()
        this.getCommentData()
        this.getRateToken()
        this.getRateProportion()
      }
    },
    computed: {
      user_id() {
        return this.$store.state.id
      },
      token() {
        return this.$store.state.token
      },
    },
    methods: {
      toLastPage(){
        this.$store.dispatch('isLastPage', {
          isLastPage: true
        })
        this.$router.go(-1);
      },
      // 附件和图片点击展开
      openImageProxy: function (event) {
        if (event.target.src) {
          this.bigImgView = !this.bigImgView;
          this.bigImgSrc = event.target.src;
          const viewer = this.$refs['viewer'].$viewer
          viewer.show()
        }
      },
      //下载
      async download() {
        const {data: res} = await download({
          appId: this.app_id
        })
      },
      //获取评分比例
      async getRateProportion() {
        const {data: res} = await getRateProportion({
          appId: this.app_id
        })
        if (res.code !== 200) return
        this.rateProportionList = res.data
      },
      //获取用户评分状态
      async getRateToken() {
        const {data: res} = await getRateToken({
          appId: this.app_id
        })
        // console.log(res)
        if (res.code === 403 && res.message.indexOf("failed")) {
          this.isLogin = false

        } else if (res.code === 200) {
          this.isLogin = true
          this.finishRateValue = res.data && res.data.score
        }
      },
      //用户评分功能
      async rateChange() {
        if (this.token) {
          if (this.rateValue) {
            const obj = {}
            obj.appId = this.app_id
            obj.score = this.rateValue
            obj.commenterUid = this.user_id
            const {data: res} = await softRate(obj)
            //console.log(res)
            if (res.code === 403) {
              this.$store.dispatch('changeDialogShow', {
                loginDialogVisible: true
              })
            } else if (res.code === 200) {
              this.rateValue = null
              this.getRateToken()
              this.getData()
              this.getRateProportion()
            }
          } else {
            this.$message.error('您还没有打分')
          }
        } else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }
      },
      // 点赞方法
      async changeRate(commID) {
        if (this.token) {
          const obj = {}
          obj.likeUid = this.user_id
          obj.commentId = commID
          const {data: res} = await DetailRateZan(obj)
          // console.log(res)
          if (res.code !== 200) return
          if (res.data.status === 1) {
            this.isLikeComment = true
          } else {
            this.isLikeComment = false
          }
          this.getCommentData()

        } else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }

      },
      //当前页码书改变
      currentChange(index) {
        this.currentPage = index
        this.getCommentData()
      },
      //发表评论
      async setComments() {
        if (this.token) {
          if (this.comment.trim() !== '') {
            if (this.comment.length >= 10) {
              const {data: res} = await setComments({
                commenterUid: this.user_id,
                appId: this.app_id,
                content: this.comment,
                parentId: "0"
              })
              // console.log(res)
              if (res.code === 403 && res.message.indexOf("failed")) {
                this.$store.dispatch('changeDialogShow', {
                  loginDialogVisible: true
                })
              } else if (res.code === 200) {
                this.$message.success('评论成功，待审核')
                this.comment = ''
                this.getCommentData()
              } else {
                this.$message.error(`${res.message}`)
              }
            } else {
              this.$message.error('评论内容需10个字以上')
            }

          } else {
            this.$message.error('请填写评论内容')
            this.comment = ''
          }
        } else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }

      },
      async getCommentData() {
        const {data: res} = await getComments({
          userId: this.user_id,
          appId: this.app_id,
          page: this.currentPage,
          per_page: this.pageSize,
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        // console.log(res)
        this.total = res.data.total
        this.commentList = res.data.vodata

      },
      /* 获取软件详情数据 */
      async getData() {
        const {data: res} = await getSoftDetail({
          process: 'INFO',
          id: this.app_id,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataObj = res.data
        //this.$message.success('获取成功')
        // console.log(res)
      }
    },
    components: {}
  }
</script>

<style lang="less" scoped>
.viewer-dialog-box {
  .img-box {
    height: 0px;
    margin: 0 auto;
    .img-wrap {
      width: 0;
      height: 0;
      position: relative;
      cursor: pointer;
      .img-wrap > div {
        height: 100%;
        width: 100%;
      }
      img {
        height: 0;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
  .detail {
    width: 80%;
    margin: 20px auto;
    //padding: 20px;

    /* 面包屑导航 */
    .el-breadcrumb {
      margin-left: 10px;
      @include ptrbl(16px,16px,16px,16px);
      box-sizing: border-box;
      width: 50%;
    }
    .el-breadcrumb-button{
      img{
        height: 14px;
        margin-right: 5px;
      }
      background-color: rgba(0, 0, 0, 0);
      margin-right: 10px;
      position:relative;
      margin-top: -17px;
      float:right;
      font-weight: 700;
      text-decoration: none;
      border: none;
      transition: color .2s cubic-bezier(.645,.045,.355,1);
      color: #303133;
      vertical-align: middle;
      &:hover {
        color: red;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 160px;
      background-color: #fff;
      margin-top: 10px;
      margin-bottom: 20px;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, .3);

      .left {
        img {
          width: 140px;
          height: 128px;
          object-fit: cover;
        }
      }

      .center {
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        background-color: #fff;
        padding-right: 60px;

        .one {
          display: flex;
          justify-content: space-between;

          .one_name {
            span:nth-child(1) {
              font-size: 18px;
              font-weight: 700;
              margin-right: 20px;
            }
          }

          .one_ping {
            .el-icon-star-on {
              border: 1px solid red;
            }
          }

        }

        .two {
          width: 100%;
          justify-content: space-between;
          display: flex;
        }

        .three {
          font-size: 12px;
          line-height: 20px;
          color: #797979;
        }

      }

      .right {
        .download {
          width: 160px;
        }
      }
    }

    .banner {
      position: relative;
      margin-right: 11px;
      box-sizing: border-box;
      /*width: 219px;*/
      /*height: 131px;*/
      margin: 35px;
      height: 330px;

      .el-carousel__mask{
        opacity: 0;
      }

      .a {
        //color: #fff;
      }

      img {
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top:0;
        right: 0;
        margin: auto;
        z-index: 1000;
      }
    }

    .pro {
      background-color: #fff;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, .3);
      margin-bottom: 20px;
      padding: 16px;

      .tit {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 18px;
      }

      .cont {
        margin-top: 10px;
        line-height: 20px;
      }

    }

    .rate {
      margin-top: 20px;

      .rate_tit {
        font-size: 18px;
        font-weight: 700;
      }

      .rate_content {
        padding: 0 40px;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        .rate_content_one {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .rate_left {
            min-width: 134px;

            .big {
              font-size: 80px;
              font-weight: 700;
              color: red
            }

            .small {
              font-size: 18px;
              color: #797979;
            }

            .tu {
              margin: 18px 0;
            }
          }

          .rate_right {
            min-width: 190px;
            margin-left: 200px;
            .rate_right_item {
              //width: 120%;
              height: 20px;
              line-height: 20px;
              display: flex;
              .rate_right_item_line {
                width: 30px;
                margin: 8px 8px 0;
                border-top: 4px solid #797979;
              }
              .text {
                font-size: 12px;
                color: #797979;
              }
            }
          }
        }

        .line {
          border-left: 1px solid #797979;
          height: 100px;
          margin: 0 80px 0 100px;
        }

        .rate_content_two {
          min-width: 222px;
          margin-top: 30px;
          width: 30%;
          color: #797979;
          font-size: 12px;
          .top_ {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
          }
        }

      }
    }

    .comment {
      margin: 30px 0;
      background-color: #fff;

      border-radius: 10px;
      padding: 20px;

      .coo_top {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .comm_content {
        border-bottom: 1px solid #ccc;

        .item {
          display: flex;
          height: 100px;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #ccc;

          .left {
            display: flex;
            align-items: center;

            .one {
              margin-right: 12px;

              img {
                width: 51px;
                height: 51px;
                border-radius: 50%;
              }
            }
          }

          .right {
            text-align: left;

            .comm {
              cursor: pointer;
              color: #ed1b24;
            }
          }
        }

      }


    }


  }

</style>
